<template>
  <div class="h5">
    <div class="h5-content">
      <div class="qrcode">
        <div></div>
        <Button @click="copy">复制链接</Button>
        <div class="wraper">
          <div class="outter">
            <vue-qr :logoSrc="gree" :text="qrcodeUrl" :margin="0" :size="100"></vue-qr>
          </div>
          <div class="gap txt">扫一扫进入</div>
          <div class="txt">格力董明珠店铺</div>
        </div>
      </div>
      <div class="phone">
        <iframe :src="url" style="width: 414px; height: 896px" frameborder="0" />
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import VueQr from 'vue-qr' // https://github.com/Binaryify/vue-qr
let pageList = ['/newIndex', '/newLogin', '/shopDetail']
let pageMapList = ['/home', '/login', '/goodsDetail']

export default {
  name: 'H5',
  components:{
    VueQr
  },
  data() {
    return {
      gree: require('@/assets/imgs/home/gree.jpg'), // gree
      url: '',
      qrcodeUrl: '',
    }
  },
  methods: {
    pathMap(path) {
      pageList.forEach((item, index) => {
        path = path.replace(item, pageMapList[index])
      })
      path = path.replace(/id/, 'productNo')// 处理一下商详参数
      path = path.replace(/skid/, 'skuNo')// 处理一下商详参数
      return path
    },
    copy() {
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.setAttribute('value', this.qrcodeUrl)
      input.readOnly = 'readOnly'
      input.select()
      if (document.execCommand('copy')) {
        //执行浏览器复制命令
        document.execCommand('copy')
      }
      document.body.removeChild(input)
      this.$Message.success('复制成功!');
    },
    listenMessage(event) {
      if (event.data?.type !== 'replacePath') {
        return;
      }
      const path = event.data?.path || '';
      let fullPath = event.data?.value || '';
      if(pageList.includes(path)) {
        // PC有的页面用PC的显示
        this.$router.push(this.pathMap(fullPath))
      } else {
        // 用H5显示
        this.qrcodeUrl = config.H5_LINK + fullPath.substring(1)
        history.replaceState(null, null, window.location.origin + '/#/h5'+fullPath);
      }
    },
  },
  activated() {
    window.addEventListener('message', this.listenMessage, false);
  },
  deactivated() {
    window.removeEventListener('message', this.listenMessage, false);
  },
  created() {
    this.qrcodeUrl = this.url = config.H5_LINK + this.$route.fullPath.replace(/\/h5\//, '')
  },
}
</script>

<style lang="less" scoped>
.h5 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #FFFFFF;
  .h5-content {
    width: 956px;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: space-around;
    .qrcode {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      button {
        background-color: #CC0000;
        color: white;
      }
      .wraper {
        .outter {
          border: 1px solid #F1F1F1;
          border-radius: 20px;
          padding: 15px;
          font-size: 0;
        }
        .gap {
          margin-top: 17px;
        }
        .txt {
          font-size: 16px;
          line-height: 24px;
          color: #333333;
          text-align: center;
        }
      }
    }
    .phone {
      width: 600px;
      height: 100%;
      iframe {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>